import { useCallback } from 'react';
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
} from 'react-router-dom';

export function useNavigateWithTransition() {
  const reactRouterNavigate = useNavigate();

  const navigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      // If the browser doesn't support transitions, just navigate
      if (!document.startViewTransition) {
        return reactRouterNavigate(to, options);
      } else {
        document.startViewTransition(() => {
          reactRouterNavigate(to, options);
        });
      }
    },
    [reactRouterNavigate],
  );

  return navigate as NavigateFunction;
}
