import { Typography } from '@taxfix/ds-components';
import { AppleLogo } from '../images';
import './apple-button.css';

type Props = {
  onClick: () => void;
  text: string | React.ReactElement;
};

export const AppleButton = ({ onClick, text }: Props) => (
  <button type="button" className="apple-btn" onClick={onClick}>
    <AppleLogo />
    <Typography color="text.contrastText" variant="button">
      {text}
    </Typography>
  </button>
);
