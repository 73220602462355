import { Box, Button, Stack, Typography } from '@taxfix/ds-components';
import { LinkText } from '../../components/link-text';

import { SocialConnection } from '../../types';
import { useSignupContext } from '../signup-container';
import { Trans, useTranslation } from 'react-i18next';
import EnvelopeIcon from '@taxfix/ds-icons/Envelope';
import { GoogleButton } from '../../components/google-button';
import { AppleButton } from '../../components/apple-button';

export const SignupStepOne = () => {
  const { t } = useTranslation();
  const { onClickSocialSignup, onClickEmailSignup } = useSignupContext();

  return (
    <Stack height="100%" space={{ md: 4 }} justifyContent="space-between">
      <Stack space={3}>
        <Stack space={1} p={3} pb={0}>
          <Box>
            <Typography variant={'h1'}>{t('signUp.title')}</Typography>
          </Box>
          <Box>
            <Typography variant={'bodySmall'}>
              <Trans
                i18nKey="signUp.subTitle"
                components={{
                  link1: (
                    <LinkText
                      to={t('signUp.agbLink')}
                      title="link1"
                      size="small"
                    />
                  ),
                  link2: (
                    <LinkText
                      to={t('signUp.tacLink')}
                      title="link2"
                      size="small"
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        </Stack>

        <Stack space={2} px={3} pb={{ sm: 3 }}>
          <Box>
            <Button
              testID="signup-with-email-button"
              onPress={onClickEmailSignup}
              startIcon={<EnvelopeIcon />}
            >
              {t('signUp.withEmail')}
            </Button>
          </Box>
          <Box>
            <AppleButton
              onClick={() => onClickSocialSignup(SocialConnection.apple)}
              text={t('signUp.withApple')}
            />
          </Box>
          <Box>
            <GoogleButton
              onClick={() => onClickSocialSignup(SocialConnection.google)}
              text={t('signUp.withGoogle')}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
