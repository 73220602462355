// This file is for configuration for local development and tests

import { AuthFlow } from './types';

export const baseUrl = process.env.REACT_APP_BASE_URL;

// TODO: To improve DX we should have a way to switch this dynamically directly in the UI
const authFlow = AuthFlow.SIGNUP;
const screenHint = JSON.stringify({
  status: 'email_invalid_or_in_use',
  connection: 'apple',
});
const showError = false;

const devConfig = {
  icon: 'https://storage.googleapis.com/txf-auth0-assets/images/taxfix.svg',
  assetsUrl: '',
  auth0Domain: 'sso.dev.taxfix.com',
  auth0Tenant: 'taxfix-development',
  clientConfigurationBaseUrl: 'https://sso.dev.taxfix.com/',
  callbackOnLocationHash: false,
  callbackURL: 'http://localhost:3000/signup-callback',
  cdn: 'https://cdn.auth0.com/',
  clientID: 'Zhg4HSJWOLzXlL4u0PoKjocFUirmG4IU',
  supportURL: 'https://support.taxfix.de/',
  dict: {
    signin: {
      title: 'Web-App DE',
    },
  },
  extraParams: {
    protocol: 'oauth2',
    audience: 'https://integration.dev.taxfix.tech/api',
    scope: 'openid profile email customer offline_access',
    authFlow,
    userSignupData:
      '{"way":"{}","tac":"3.1.0","privacy":"3.0.0","year":"2022","acceptedPromotionalEmails":"false","isWeb":"false","deviceId":"c89afc954e775cd6cb22a72d2703864e","countryCode":"DE","metadata":"{\\"productLine\\":\\"DIY\\",\\"platform\\":\\"web\\",\\"deviceType\\":\\"Desktop\\"}"}',
    prompt: 'login',
    response_type: 'code',
    response_mode: 'query',
    nonce: 'cU03M0N5V1lNaGE3cDcuR3ZrNVdhZ2dwYTlWVH5qNEpSNlVGVWhwWWR2QQ==',
    code_challenge: 'XPSNuXJWkZeGX-2suG3Ct-gz8jYcQSUDXDhMdW5zQWs',
    code_challenge_method: 'S256',
    auth0Client: 'eyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS4xMi4wIn0=',
    _csrf: 'X7S5GorP-MQsgUkQFlJCOe3lM89c0YVd2pHE',
    _intstate: 'deprecated',
    state:
      'hKFo2SBJX21YQnY4NE9CSVNZdXVYYy05RXZiVDE1bDRUUkdicKFupWxvZ2luo3RpZNkgMmh0TllkQnVqSV9QQUhaendrQnhVSUVCWDJSLUpfWEijY2lk2SBnTW9xV2dGN3dvelJVSXlLejFpSk8xQW9ybmF6UGZTaA',
    screen_hint: String(showError && screenHint),
    link_account_token:
      '<create a child token at jwt.io with an email payload>',
    flow: authFlow,
    cancelAccountLinkingUrl: 'http://localhost:3000',
    login_hint: 'test@taxfix.dev',
    // ff_signup_password_required: 'true',
  },
  internalOptions: {
    protocol: 'oauth2',
    audience: 'https://integration.dev.taxfix.tech/api',
    scope: 'openid profile email customer offline_access',
    authFlow,
    userSignupData:
      '{"way":"{}","tac":"3.1.0","privacy":"3.0.0","year":"2022","acceptedPromotionalEmails":"false","isWeb":"false","deviceId":"c89afc954e775cd6cb22a72d2703864e","countryCode":"DE","metadata":"{\\"productLine\\":\\"DIY\\",\\"platform\\":\\"web\\",\\"deviceType\\":\\"Desktop\\"}"}',
    prompt: 'login',
    response_type: 'code',
    response_mode: 'query',
    nonce: 'cU03M0N5V1lNaGE3cDcuR3ZrNVdhZ2dwYTlWVH5qNEpSNlVGVWhwWWR2QQ==',
    code_challenge: 'XPSNuXJWkZeGX-2suG3Ct-gz8jYcQSUDXDhMdW5zQWs',
    code_challenge_method: 'S256',
    auth0Client: 'eyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS4xMi4wIn0=',
    _csrf: 'X7S5GorP-MQsgUkQFlJCOe3lM89c0YVd2pHE',
    _intstate: 'deprecated',
    state:
      'hKFo2SBJX21YQnY4NE9CSVNZdXVYYy05RXZiVDE1bDRUUkdicKFupWxvZ2luo3RpZNkgMmh0TllkQnVqSV9QQUhaendrQnhVSUVCWDJSLUpfWEijY2lk2SBnTW9xV2dGN3dvelJVSXlLejFpSk8xQW9ybmF6UGZTaA',
  },
  prompt: true,
  widgetUrl: 'https://cdn.auth0.com/w2/auth0-widget-5.1.min.js',
  isThirdPartyClient: false,
  authorizationServer: {
    issuer: 'https://sso.dev.taxfix.com/',
  },
  colors: {
    page_background: '#ffffff',
    primary: '#32c850',
  },
};

export const featureFlagConfig = {
  prefix: 'ff_',
  defaultValue: 'false',
};

const testConfig: Auth0Config =
  process.env.REACT_APP_AUTH0_CONFIG &&
  JSON.parse(
    decodeURIComponent(window.atob(process.env.REACT_APP_AUTH0_CONFIG)),
  );

export const isDev = process.env.NODE_ENV === 'development';
export const isTest = process.env.NODE_ENV === 'test';
export const auth0Config = isTest
  ? testConfig
  : (devConfig as unknown as Auth0Config);
