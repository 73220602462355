import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Link, Typography } from '@taxfix/ds-components';

import { useSnackbar } from '../hooks/snack-bar';

type Props = {
  isDisabled: boolean | undefined;
  onPress: () => void;
  lastOtpTimestamp: Date;
  resendCodeIn: number;
};

export const OtpCountdownButton = ({
  isDisabled,
  onPress,
  lastOtpTimestamp,
  resendCodeIn,
}: Props) => {
  const { showSnackbar } = useSnackbar();

  const [countdown, setCountdown] = useState(resendCodeIn);
  const formatedCountdown =
    Math.floor(countdown / 60) + ':' + String(countdown % 60).padStart(2, '0');

  const startCountdown = () => {
    setCountdown(resendCodeIn);
  };

  const handleResendCode = () => {
    if (countdown > 0) {
      showSnackbar({
        translationKey: 'oneTimePassword.resendCodeCountdownError',
      });
    } else {
      onPress();
    }
  };

  useEffect(() => {
    if (countdown > 0)
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
  }, [countdown]);

  useEffect(() => {
    startCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOtpTimestamp]);

  return (<Link onPress={handleResendCode}>
    <Typography
      pt={2}
      textAlign="center"
      color="primary.main"
      variant="body"
    >
      <Trans
        i18nKey={'oneTimePassword.resendCode' + (countdown > 0 ? 'Countdown' : '')}
        values={{ formatedCountdown }}
      />
    </Typography>
  </Link>);
};
