import { useCallback } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { AuthenticationHook, useAuthentication } from '../hooks/authentication';
import { routes } from '../router/routes';
import { useHandleSocialAuthError } from '../hooks/handle-social-auth-error';
import { useNavigateWithTransition } from '../hooks/navigate-with-transition';

export const LoginContainer = () => {
  useHandleSocialAuthError();
  const navigate = useNavigateWithTransition();
  const {
    handlePasswordlessStart,
    handleSocialLogin,
    isLoading,
    handleEmailPasswordLogin,
  } = useAuthentication();
  const { state } = useLocation();

  const userEmail = state?.email || '';

  const handleEmailLogin = useCallback(() => {
    navigate(routes.emailLogin);
  }, [navigate]);

  return (
    <Outlet
      context={{
        onClickSocialLogin: handleSocialLogin,
        onClickEmailLogin: handleEmailLogin,
        onPasswordlessStart: handlePasswordlessStart,
        onEmailPasswordLogin: handleEmailPasswordLogin,
        loading: isLoading,
        userEmail,
      }}
    />
  );
};

type LoginHandlers = {
  onClickSocialLogin: AuthenticationHook['handleSocialLogin'];
  onClickEmailLogin: () => void;
  onPasswordlessStart: AuthenticationHook['handlePasswordlessStart'];
  onEmailPasswordLogin: AuthenticationHook['handleEmailPasswordLogin'];
  loading?: boolean;
  userEmail: string;
};

export function useLoginContext() {
  return useOutletContext<LoginHandlers>();
}
