import type { AuthOptions } from 'auth0-js';

export function createWebAuthParams(config: Auth0Config): AuthOptions {
  return {
    overrides: {
      __tenant: config.auth0Tenant,
      __token_issuer: config?.authorizationServer?.issuer,
    },
    domain: config.auth0Domain,
    clientID: config.clientID,
    redirectUri: config.callbackURL,
    responseType: 'code',
    state:
      config.internalOptions?.original_state || config.internalOptions?.state,
    ...config.internalOptions,
  };
}
