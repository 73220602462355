import { useAuth0 } from './auth0-hook';
import { useSnackbar } from './snack-bar';
import { useCallback, useEffect, useState } from 'react';

export function useHandleSocialAuthError() {
  const [error, setError] = useState<string>();
  const { config, isSignup } = useAuth0();
  const { showSnackbar } = useSnackbar();

  const getSocialAuthErrorMessage = useCallback(() => {
    try {
      if (!config?.extraParams?.screen_hint) {
        return;
      }
      // TODO: Refactor the screenHint.status to be a string enum
      const screenHint = JSON.parse(config.extraParams.screen_hint);

      if (!screenHint || !screenHint?.status) {
        return;
      }

      if (screenHint.status === 'email_invalid_or_in_use') {
        if (!isSignup) {
          return;
        }
        return 'signUp.error.somethingWentWrong';
      }

      return 'login.error.invalidSocialCredentials';
    } catch (e) {
      return;
    }
  }, [config.extraParams?.screen_hint, isSignup]);

  useEffect(() => {
    const errorMessage = getSocialAuthErrorMessage();
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [getSocialAuthErrorMessage]);

  showSnackbar({
    translationKey: error,
    onClose: () => setError(undefined),
  });
}
