import { Link } from '@taxfix/ds-components';

type Props = {
  children?: string;
  size?: 'medium' | 'small';
  title: string;
  to: string;
};

export const LinkText = (props: Props) => {
  return (
    <Link
      testID={props.title}
      href={props.to || '#'}
      size={props.size || 'medium'}
    >
      {props.children}
    </Link>
  );
};
