import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '../hooks/auth0-hook';
import { useAuthentication } from '../hooks/authentication';
import { AuthFlow, SocialConnection } from '../types';
import { decodeJWT } from '../utils/jwt-utils';
import { OtpInputContainer } from '../shared/one-time-password/one-time-password-container';

export const AccountLinkingOtpContainer = () => {
  const { config } = useAuth0();
  const { handlePasswordlessStart, handleSocialLogin, authFlow } =
    useAuthentication();
  const isAccountLinking = Boolean(authFlow === AuthFlow.ACCOUNT_LINKING);
  const [loadingAccountLinking, setLoadingAccountLinking] =
    useState(isAccountLinking);
  const { connection } = config;
  const socialConnection: SocialConnection | undefined = useMemo(
    () =>
      Object.values(SocialConnection).find(provider => provider === connection),
    [connection],
  );
  const email = useMemo(() => {
    const token = config?.extraParams?.link_account_token;
    if (!token) return;

    const decodedToken = decodeJWT(token);
    return decodedToken?.email;
  }, [config?.extraParams?.link_account_token]);

  useEffect(() => {
    if (isAccountLinking && loadingAccountLinking) {
      if (socialConnection) {
        handleSocialLogin(socialConnection);
        return;
      }
      setLoadingAccountLinking(false);
    }
  }, [
    connection,
    email,
    handlePasswordlessStart,
    handleSocialLogin,
    isAccountLinking,
    socialConnection,
    loadingAccountLinking,
  ]);

  useEffect(() => {
    if (email && !loadingAccountLinking) {
      handlePasswordlessStart(email);
    }
  }, [email, handlePasswordlessStart, loadingAccountLinking]);

  if (loadingAccountLinking) return null;

  return <OtpInputContainer email={email} mode='accountLinking' />;
};
