import { type ThemeName } from '@taxfix/ds-theme';

export const databaseConnection = 'Username-Password-Authentication';

export const baseURL: string = process.env.REACT_APP_API_BASE_URL!;
export const accountLinkingCancelUrl: string =
  process.env.REACT_APP_ACCOUNT_LINKING_CANCEL_URL!;
export const designSystemTheme = process.env
  .REACT_APP_DESIGN_SYSTEM_THEME! as ThemeName;
export const segmentWriteKey: string = process.env.REACT_APP_SEGMENT_WRITE_KEY!;

export const FEATURE_FLAG: Record<string, string> = {
  signup_password_required: 'signup_password_required',
} as const;

const FEATURE_FLAGS = Object.values(FEATURE_FLAG);

export type FeatureFlagName = (typeof FEATURE_FLAGS)[number];
export const OTP_EXPIRATION_SECONDS = 180;
