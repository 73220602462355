import { ExtraParams, LoginForm, SignupSubmitForm, SocialConnection } from '../types';
import { useAuth0 } from './auth0-hook';
import { useSnackbar } from './snack-bar';
import { useCallback, useState } from 'react';
import { databaseConnection } from '../constants';
import { useTranslation } from 'react-i18next';
import { usersApiClient } from '../utils/users-api';
import { trackLoginAttempt, trackRegistrationAttempt } from '../utils/tracking';

export type AuthenticationHook = ReturnType<typeof useAuthentication>;

export function useAuthentication() {
  const [translationKey, setTranslationKey] = useState<string>();
  // TODO: Remove this state and use the translationKey instead
  const [signupError, setSignupError] = useState<string>();
  const { webAuth, authFlow, config } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { i18n } = useTranslation();

  const handleSocialLogin = useCallback(
    (conn: SocialConnection) => {
      trackLoginAttempt(conn);
      webAuth.authorize({
        connection: conn,
      });
    },
    [webAuth],
  );

  const handleSocialSignup = useCallback(
    (conn: SocialConnection) => {
      trackRegistrationAttempt(conn);
      webAuth.authorize({
        connection: conn,
      });
    },
    [webAuth],
  );

  const clearErrors = useCallback(() => {
    if (translationKey || signupError) {
      setTranslationKey(undefined);
      setSignupError(undefined);
    }
  }, [translationKey, signupError]);

  const handleEmailPasswordLogin = useCallback(
    (data: LoginForm, onError?: ()=>void | undefined ) => {
      clearErrors();
      setIsLoading(true);
      trackLoginAttempt('email-password');

      const loginParams = {
        ...data,
        realm: databaseConnection,
      };
      webAuth.login(loginParams, function (err) {
        if (err) {
          if (onError !== undefined) {
            return onError()
          }
          setTranslationKey('login.error.invalidEmailCredentials');
          setIsLoading(false);
        }
      });
    },
    [clearErrors, webAuth],
  );

  const passwordlessStart = useCallback(
    (email: string, { onError, onSuccess }: ExtraParams) => {
      clearErrors();
      setIsLoading(true);
      trackLoginAttempt('email-otp');

      webAuth.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email,
          // @ts-expect-error - this is not in the types https://auth0.github.io/auth0.js/WebAuth.html#passwordlessStart
          xRequestLanguage: i18n.resolvedLanguage,
        },
        function (err) {
          setIsLoading(false);

          if (err) {
            if (onError) {
              onError(err);
            }
            return;
          }

          if (onSuccess) {
            onSuccess();
          }
        },
      );
    },
    [clearErrors, i18n.resolvedLanguage, webAuth],
  );

  const handleResendOneTimePassword = useCallback(
    (email: string, params: ExtraParams = {}) => {
      passwordlessStart(email, params);
    },
    [passwordlessStart],
  );

  const handlePasswordlessStart = useCallback(
    (email: string, params: ExtraParams = {}) => {
      passwordlessStart(email, params);
    },
    [passwordlessStart],
  );

  const handlePasswordlessLogin = useCallback(
    (email: string, otp: string, { onError, onSuccess }: ExtraParams = {}) => {
      clearErrors();
      setIsLoading(true);

      webAuth.passwordlessVerify(
        {
          connection: 'email',
          email,
          verificationCode: otp,
          // @ts-expect-error - this is not in the types
          onRedirecting: function (done: () => void) {
            if (onSuccess) {
              onSuccess();
            }
            done();
          },
        },
        function (err) {
          if (err) {
            if (onError) {
              onError(err);
            }
            setIsLoading(false);
            return;
          }
        },
      );
    },
    [clearErrors, webAuth],
  );


  const setSignupErrorMessage = useCallback(()=>{
    setSignupError('signUp.error.somethingWentWrong');
  }, [setSignupError]);

  const handleEmailPasswordSignup = useCallback(
    (data: SignupSubmitForm, onError: (err: any)=>void) => {
      clearErrors();
      setIsLoading(true);
      trackRegistrationAttempt('email-password');

      const signupCreds = {
        ...data,
        connection: databaseConnection,
        userMetadata: {
          ...JSON.parse(config.extraParams?.userSignupData || '{}'),
          lang: i18n.resolvedLanguage,
        },
      };

      webAuth.signup(signupCreds, function (err: any) {
        if (err) {
          setIsLoading(false);
          onError(err);
          return;
        }
        return handleEmailPasswordLogin(data, ()=>{
          setSignupError('signUp.error.somethingWentWrong');
          setIsLoading(false);
        });
      });
    },
    [clearErrors, config.extraParams?.userSignupData, handleEmailPasswordLogin, i18n.resolvedLanguage, setSignupError, webAuth],
  );

  const handleUnblockAccount = useCallback(
    async (email: string, otc: string) => {
      setIsLoading(true);
      try {
        await usersApiClient.unblockUser({ email, otc });
        setTranslationKey('unblockAccount.snackbar.accountUnblocked');
      } catch (err: any) {
        const errorMessage = err?.response?.data || err?.message;
        throw new Error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const handleResendUnblockCode = useCallback(async (email: string) => {
    setIsLoading(true);
    try {
      await usersApiClient.resendUnblockOTP({ email });
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  showSnackbar({
    translationKey: translationKey,
    onClose: () => setTranslationKey(undefined),
  });

  return {
    handleSocialLogin,
    handleSocialSignup,
    handleEmailPasswordLogin,
    handleEmailPasswordSignup,
    setSignupErrorMessage,
    isLoading,
    authFlow,
    error: translationKey || signupError,
    clearErrors,
    handlePasswordlessStart,
    handlePasswordlessLogin,
    handleResendOneTimePassword,
    handleResendUnblockCode,
    handleUnblockAccount,
  };
}
