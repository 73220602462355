import { createHashRouter } from 'react-router-dom';
import { AccountLinkingOtpContainer } from '../account-linking/account-linking-otp-container';
import App from '../app';
import ErrorPage from '../error-page/error-page';
import { LoginProviderSelection } from '../login/components/login-provider-selection/login-provider-selection';
import { EmailInputScreen } from '../login/components/login-with-email/email-input-screen';
import { LoginContainer } from '../login/login-container';
import { ReAuthenticateOtpContainer } from '../re-authenticate/re-authenticate-otp-container';
import { SignupStepOne } from '../signup/components/signup-step-one';
import { SignupStepTwo } from '../signup/components/signup-step-two';
import { SignupContainer } from '../signup/signup-container';
import { routes } from './routes';
import { OtpInputContainer } from '../shared/one-time-password/one-time-password-container';
import { UnblockAccountContainer } from '../unblock-account/unblock-account-container';
import { LoginWithEmailPassword } from '../login/components/login-with-email-password/login-with-email-password';
import { ReAuthenticatePasswordContainer } from "../re-authenticate/re-authenticate-password-container";
import { ResetPasswordContainer } from '../reset-password/reset-password-container';
import { AccountLinkingPasswordContainer } from '../account-linking/account-linking-password-container';
import { AccountLinkingPasswordStep } from '../account-linking/components/account-linking-password-step';

export const router = createHashRouter([
  {
    path: routes.home,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.login,
        element: <LoginContainer />,
        children: [
          {
            index: true,
            element: <LoginProviderSelection />,
          },
          {
            path: routes.emailLogin,
            children: [
              {
                index: true,
                element: <EmailInputScreen />,
              },
              {
                path: routes.otpScreen,
                element: <OtpInputContainer mode='login' />,
              },
            ],
          },
          {
            path: routes.emailPasswordLogin,
            element: <LoginWithEmailPassword />,
          },
        ],
      },
      {
        path: routes.accountLinkingOtp,
        element: <AccountLinkingOtpContainer />,
      },
      {
        path: routes.accountLinkingPassword,
        element: <AccountLinkingPasswordContainer />,
        children: [
          {
            index: true,
            element: <AccountLinkingPasswordStep />,
          },
        ],
      },
      {
        path: routes.signup,
        element: <SignupContainer />,
        children: [
          {
            index: true,
            element: <SignupStepOne />,
          },
          {
            path: routes.emailSignup,
            element: <SignupStepTwo />,
          },
        ],
      },
      {
        path: routes.reAuthenticateOtp,
        element: <ReAuthenticateOtpContainer />,
      },
      {
        path: routes.reAuthenticatePassword,
        element: <ReAuthenticatePasswordContainer />,
      },
      {
        path: routes.unblockAccount,
        element: <UnblockAccountContainer />,
      },
      {
        path: routes.resetPassword,
        element: <ResetPasswordContainer />,
      },
    ],
  },
]);
