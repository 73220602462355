import React from 'react';
import ReactDOM from 'react-dom/client';
import LinearGradient from 'react-native-web-linear-gradient';
import { RouterProvider } from 'react-router-dom';

import '@taxfix/ds-fonts';
import { DesignSystemProvider } from '@taxfix/ds-theme';

import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { router } from './router';
import { getThemeByClient } from './utils/custom-theme';
import { Auth0Provider } from './context/auth0-context';
import { FeatureFlagProvider } from './context/featureFlag-context';
import { featureFlagConfig } from './config';
import { baseURL } from './constants';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <DesignSystemProvider
      theme={getThemeByClient(window.Auth0Context.config.clientID)}
      linearGradientDependency={LinearGradient}
    >
      <Auth0Provider
        config={window.Auth0Context.config}
        baseURL={baseURL || ''}
      >
        <FeatureFlagProvider
          config={featureFlagConfig}
          params={window.Auth0Context?.config?.extraParams ?? {}}
        >
          <RouterProvider router={router} />
        </FeatureFlagProvider>
      </Auth0Provider>
    </DesignSystemProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
