import { useAuth0Context } from '../context/auth0-context';
import { routes } from '../router/routes';
import { AuthFlow } from '../types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useAuth0 = () => {
  const { config, ...rest } = useAuth0Context();
  const location = useLocation();
  const authFlow = useMemo(
    () => config?.extraParams?.flow || config?.extraParams?.authFlow,
    [config?.extraParams?.authFlow, config?.extraParams?.flow],
  );
  const isSignup = useMemo(
    () =>
      authFlow === AuthFlow.SIGNUP && location.pathname.includes(routes.signup),
    [authFlow, location.pathname],
  );

  /**
   * Indicates whether the platform is Android.
   */
  const isAndroid = useMemo(() => rest.platform === 'android', [rest.platform]);

  return { ...rest, config, authFlow, isSignup, isAndroid };
};
