import { AnalyticsBrowser } from '@segment/analytics-next'
import { segmentWriteKey } from '../constants';
import { SocialConnection } from '../types';
import { isTest } from '../config';

if (!segmentWriteKey && !isTest) {
  console.error('Segment API key not defined');
}

type TrackingParameters = {
  anonymousId?: string;
  platform?: string;
  taxCountry?: string;
};

const urlParams = new URLSearchParams(window.location.search);

const trackingParamsJson = urlParams.get('trackingParams');
const trackingParams: TrackingParameters = trackingParamsJson ? JSON.parse(trackingParamsJson) : {};
const attemptId: string | undefined = urlParams.get('attemptId') || undefined;

export const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

if (trackingParams.anonymousId) {
  analytics.setAnonymousId(trackingParams.anonymousId);
}

export type AuthStrategy = 'email-password' | 'email-otp' | SocialConnection;

export const trackLoginAttempt = async (strategy: AuthStrategy) => {
  analytics.track('Auth0LoginAttempt', {
    attemptId,
    strategy,
    ...trackingParams,
  });
};

export const trackRegistrationAttempt = async (strategy: AuthStrategy) => {
  analytics.track('Auth0RegistrationAttempt', {
    attemptId,
    strategy,
    ...trackingParams,
  });
};