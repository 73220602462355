interface Props {
  fill?: string;
}

export const AppleLogo = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    className="injected-svg"
    data-src="/static/media/apple-logo.dc70b6a6.svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M17.9219 13.0078C17.9219 11.5625 18.5859 10.5078 19.875 9.6875C19.1328 8.63281 18.0391 8.08594 16.5938 7.96875C15.1875 7.85156 13.6641 8.75 13.1172 8.75C12.5312 8.75 11.2031 8.00781 10.1484 8.00781C7.96094 8.04688 5.65625 9.72656 5.65625 13.2031C5.65625 14.2188 5.8125 15.2734 6.20312 16.3672C6.71094 17.8125 8.50781 21.3281 10.3828 21.25C11.3594 21.25 12.0625 20.5469 13.3516 20.5469C14.6016 20.5469 15.2266 21.25 16.3203 21.25C18.2344 21.25 19.875 18.0469 20.3438 16.6016C17.8047 15.3906 17.9219 13.0859 17.9219 13.0078ZM15.7344 6.60156C16.7891 5.35156 16.6719 4.17969 16.6719 3.75C15.7344 3.82812 14.6406 4.41406 14.0156 5.11719C13.3125 5.89844 12.9219 6.875 13 7.92969C14.0156 8.00781 14.9531 7.5 15.7344 6.60156Z"
      fill={props.fill || 'white'}
    ></path>
  </svg>
);
