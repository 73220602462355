import { ThemeName } from '@taxfix/ds-theme';
import { designSystemTheme } from '../constants';

const taxfixThemes: Record<string, ThemeName> = {
  taxfixLight: 'taxfix-light',
  taxfixDark: 'taxfix-dark',
  taxfixLegacy: 'taxfix-legacy',
};

// NOTE: Map here your client IDs added to the environment variables
const taxfixClient = {
  taxfixWebES: process.env.REACT_APP_TAXFIX_WEB_ES_CLIENT_ID || '',
};

// NOTE: Map here your client IDs to the theme you want to use
export const getThemeByClient = (clientId: string) => {
  switch (clientId) {
    case taxfixClient.taxfixWebES:
      return taxfixThemes.taxfixLight;
    default:
      return designSystemTheme;
  }
};
