import { Auth0ErrorType, PasswordlessRequestOtpForm } from '../../../types';
import { getErrorMessage } from '../../../utils/validation-utils';
import { useLoginContext } from '../../login-container';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@taxfix/ds-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../router/routes';
import yup from '../../../utils/yup-extended';
import { useEffect, useRef, useState } from 'react';
import { useNavigateWithTransition } from '../../../hooks/navigate-with-transition';
import { Auth0Error } from 'auth0-js';

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const EmailInputScreen = () => {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<PasswordlessRequestOtpForm>({
    resolver: yupResolver(schema),
    values: {
      email: '',
    },
  });
  const { t } = useTranslation();
  const { onPasswordlessStart, loading } = useLoginContext();
  const navigate = useNavigateWithTransition();
  const [networkErrorKey, setNetworkErrorKey] = useState<string>();
  const inputRef = useRef<HTMLElement | null>(null);

  const handleError = (error: Auth0Error) => {
    const email = getValues('email');
    switch (error?.description) {
      case Auth0ErrorType.passwordlessUnavailable:
        navigate(`../${routes.emailPasswordLogin}`, {
          state: { email },
        });
        break;
      case Auth0ErrorType.accountBlocked:
        navigate(routes.otpScreen, { state: { email, isBlocked: true } });
        break;
      default:
        setNetworkErrorKey('login.emailInput.error.invalidEmail');
        break;
    }
  };

  const submitHandler = () => {
    if (networkErrorKey) {
      setNetworkErrorKey(undefined);
    }

    if (loading) return;

    const email = getValues('email');

    return onPasswordlessStart(email, {
      onSuccess: () => navigate(routes.otpScreen, { state: { email } }),
      onError: handleError,
    });
  };

  const emailValidationErrorText = getErrorMessage(t, errors.email?.type);
  const networkErrorText = networkErrorKey ? t(networkErrorKey) : null;
  const hasError =
    Boolean(emailValidationErrorText) || Boolean(networkErrorText);

  useEffect(() => {
    inputRef.current = document.getElementById('email');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(submitHandler)();
          }}
          noValidate
          style={{ height: '100%' }}
        >
          <Stack
            justifyContent={{ xs: 'space-between', sm: 'unset' }}
            height="100%"
            space={{ lg: 2 }}
          >
            <Stack space={4} padding={3}>
              <Stack space={1}>
                <Box>
                  <Typography variant={'h2'} color={'text.title'}>
                    {t('login.emailInput.title')}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant={'body'}>
                    {t('login.emailInput.subTitle')}
                  </Typography>
                </Box>
              </Stack>
              <Stack space={1}>
                <Box>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        nativeID="email"
                        testID="email-input"
                        label={`${t('login.emailInput.label')}`}
                        onChangeText={value => {
                          if (networkErrorKey) {
                            setNetworkErrorKey(undefined);
                          }
                          field.onChange(value);
                        }}
                        feedbackText={
                          networkErrorText || emailValidationErrorText
                        }
                        isInvalid={hasError}
                        keyboardType="email-address"
                        autoFocus
                        isRequired
                        autoComplete="email"
                      />
                    )}
                  />
                </Box>
              </Stack>
            </Stack>
            <input type="submit" hidden />
            <Box padding={3}>
              <Button
                isLoading={loading}
                onPress={handleSubmit(submitHandler)}
                testID="continue-button"
              >
                {t('login.emailInput.buttonText')}
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};
