import { type TextInput } from 'react-native';
import { Box, IconButton, TextField, TextFieldProps, Typography } from '@taxfix/ds-components';
import { ForwardedRef, forwardRef, useState } from 'react';
import Eye from '@taxfix/ds-icons/Eye';
import EyeSlash from '@taxfix/ds-icons/EyeSlash';
import CircleIcon from '@taxfix/ds-icons/Circle';
import SuccessIcon from '@taxfix/ds-icons/CheckCircleSolid';
import ErrorIcon from '@taxfix/ds-icons/TimesCircleSolid';
import { useSnackbar } from '../hooks/snack-bar';
import { Trans } from 'react-i18next';

type passwordFieldProps = TextFieldProps & {
  validatePassword?: boolean,
  passwordSubmited?: boolean,
  setPasswordSubmited?: (value: boolean) => void,
};

export const validateStrongPassword = (password: string) => {
  const minLength = password.length >= 8;
  const hasLetter = /[a-zA-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*]/.test(password);

  return {
    minLength,
    hasLetter,
    hasNumber,
    hasSpecialChar,
    isValid: minLength && hasLetter && hasNumber && hasSpecialChar,
  };
};

export const generateRandomPassword = () => {
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*';
  const allChars = upperCase + lowerCase + numbers + specialChars;

  let password = '';

  password += upperCase[Math.floor(Math.random() * upperCase.length)];
  password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  for (let i = password.length; i < 8; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
}

export const PasswordField = forwardRef(
  (props: passwordFieldProps, ref: ForwardedRef<TextInput>) => {
    const { validatePassword, passwordSubmited, setPasswordSubmited } = props;
    const [isPwdVisible, setIsPwdVisible] = useState(false);
    const [password, setPassword] = useState('');
    const validation = validateStrongPassword(password);
    const successColor = 'success.main';
    const successIcon = <SuccessIcon size='tiny' color='success.light' />;
    const errorColor = passwordSubmited ? 'error.main' : 'text.primary';
    const errorIcon = passwordSubmited ? <ErrorIcon size='tiny' color='error.main' /> : <CircleIcon size="tiny" color='text.primary' />;
    const { showSnackbar } = useSnackbar();

    const handlePasswordChange = (password: string): void => {
      setPassword(password);
    };

    if (passwordSubmited && setPasswordSubmited && !validation.isValid) {
      showSnackbar({
        translationKey: 'strongPassword.invalid',
        onClose: () => setPasswordSubmited(false)
      })
    }

    return (
      <>
        <TextField
          {...props}
          ref={ref}
          placeholder=""
          type={isPwdVisible ? 'text' : 'password'}
          onChangeText={handlePasswordChange}
          InputRightElement={
            <IconButton
              onPress={() => setIsPwdVisible(!isPwdVisible)}
              size="medium"
              testID="password-visibility-icon"
            >
              {isPwdVisible ? <EyeSlash /> : <Eye />}
            </IconButton>
          }
        />
        {validatePassword && (
          <Box>
            <Typography pt={2} pb={1}>
              <Trans i18nKey="strongPassword.requirements" />
            </Typography>
            <Typography color={validation.minLength ? successColor : errorColor}>
              {validation.minLength ? successIcon : errorIcon}&nbsp;
              <Trans i18nKey="strongPassword.minLength" />
            </Typography>
            <Typography color={validation.hasLetter ? successColor : errorColor}>
              {validation.hasLetter ? successIcon : errorIcon}&nbsp;
              <Trans i18nKey="strongPassword.hasLetter" />
            </Typography>
            <Typography color={validation.hasNumber ? successColor : errorColor}>
              {validation.hasNumber ? successIcon : errorIcon}&nbsp;
              <Trans i18nKey="strongPassword.hasNumber" />
            </Typography>
            <Typography color={validation.hasSpecialChar ? successColor : errorColor}>
              {validation.hasSpecialChar ? successIcon : errorIcon}&nbsp;
              <Trans i18nKey="strongPassword.hasSpecialChar" />
            </Typography>
          </Box >
        )}
      </>
    );
  },
);
