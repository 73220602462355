import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogText,
  DialogTitle,
} from '@taxfix/ds-components';

type Props = {
  open: boolean;
  handleClose?: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction?: () => void;
  title: string | React.ReactElement;
  body: string | React.ReactElement;
  primaryButtonText: string | React.ReactElement;
  secondaryButtonText?: string | React.ReactElement;
};

export const BasicDialog = ({
  open,
  handleClose = () => {},
  handlePrimaryAction,
  handleSecondaryAction,
  title,
  body,
  primaryButtonText,
  secondaryButtonText,
}: Props) => (
  <Dialog isOpen={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogText>{body}</DialogText>
    </DialogContent>
    <DialogActions direction={{ xs: 'column-reverse', sm: 'row' }}>
      {handleSecondaryAction && secondaryButtonText && (
        <Button variant="secondary" onPress={handleSecondaryAction}>
          {secondaryButtonText}
        </Button>
      )}
      <Button variant="primary" onPress={handlePrimaryAction}>
        {primaryButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);
