import { Box } from '@taxfix/ds-components';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordStepOne } from './components/reset-password-step-one';
import { usersApiClient } from '../utils/users-api';
import { routes } from '../router/routes';
import { useSnackbar } from '../hooks/snack-bar';

export const ResetPasswordContainer = () => {
  const { state } = useLocation();
  const [email, setEmail] = useState(state?.userEmail || '');
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const lowerCaseEmail = email.toLocaleLowerCase();
    setIsLoading(true);
    try {
      await usersApiClient.requestPasswordReset({
        email: lowerCaseEmail,
        taxCountry: 'DE',
      });
    } catch (err) {
      // Do nothing just redirect to the result page
    } finally {
      showSnackbar({
        translationKey: 'resetPassword.stepTwoSubTitle',
      });
      setIsLoading(false);
      navigate(`/${routes.login}/${routes.emailPasswordLogin}`, { state: { email } });
    }
  };

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <ResetPasswordStepOne
          setEmail={setEmail}
          onSubmit={onSubmit}
          isLoading={isLoading}
          userEmail={email}
        />
      </Box>
    </Box>
  );
};
