import { Auth0ErrorType, PasswordlessSubmitForm } from '../../../types';
import { getErrorMessage } from '../../../utils/validation-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  LoadingFullScreen,
  Stack,
  TextField,
  Typography,
} from '@taxfix/ds-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import yup from '../../../utils/yup-extended';
import { Auth0Error } from 'auth0-js';
import { AuthenticationHook } from '../../../hooks/authentication';
import { useSnackbar } from '../../../hooks/snack-bar';
import { BasicDialog } from '../../../components/basic-dialog';
import { routes } from '../../../router/routes';
import { useNavigateWithTransition } from '../../../hooks/navigate-with-transition';
import { OtpCountdownButton } from '../../../components/otp-countdown-button';
import { OTP_EXPIRATION_SECONDS } from '../../../constants';

const schema = yup
  .object({
    email: yup.string().email().required(),
    oneTimePassword: yup.string().required().length(6),
  })
  .required();

const OTP_FORMAT = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export type otpMode = 'login' | 'reAuthenticate' | 'accountLinking'

type Props = {
  email: string;
  onPasswordlessLogin: AuthenticationHook['handlePasswordlessLogin'];
  loading?: AuthenticationHook['isLoading'];
  onResendOneTimePassword: AuthenticationHook['handleResendOneTimePassword'];
  isMobile?: boolean;
  isBlocked?: boolean;
  mode: otpMode;
  resendCodeIn?: number;
};

export const OtpInputScreen = ({
  loading,
  email,
  onPasswordlessLogin,
  onResendOneTimePassword,
  isBlocked = false,
  resendCodeIn = OTP_EXPIRATION_SECONDS,
  mode,
}: Props) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<PasswordlessSubmitForm>({
    resolver: yupResolver(schema),
    values: {
      oneTimePassword: '',
      email,
    },
  });
  const inputRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const [networkErrorKey, setNetworkErrorKey] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const [snackbarTranslationKey, setSnackbarTranslationKey] =
    useState<string>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [lastOtpTimestamp, setLastOtpTimestamp] = useState(new Date());
  const [isAccountBlocked, setIsAccountBlocked] = useState(isBlocked);
  const navigate = useNavigateWithTransition();

  const handleError = useCallback((error: Auth0Error) => {
    // Handle account blocked error separately
    if (error.code === Auth0ErrorType.tooManyAttempts) {
      setIsAccountBlocked(true);
      return;
    }

    switch (error.description) {
      case Auth0ErrorType.codeExpired:
        setNetworkErrorKey('oneTimePassword.error.codeExpired');
        break;
      case Auth0ErrorType.invalidCodeOrEmail:
        setNetworkErrorKey('oneTimePassword.error.invalidCode');
        break;
      default:
        setNetworkErrorKey('oneTimePassword.error.generalError');
        break;
    }
  }, []);

  const handleClearErrors = useCallback(() => {
    clearErrors();
    setNetworkErrorKey(undefined);
  }, [clearErrors]);

  const submitHandler = () => {
    if (networkErrorKey) {
      handleClearErrors();
    }

    if (loading) return;

    return onPasswordlessLogin(email, getValues('oneTimePassword'), {
      onError: handleError,
      onSuccess: () => {
        setIsRedirecting(true);
      },
    });
  };

  const handleResendCode = useCallback(() => {
    setValue('oneTimePassword', '');
    return onResendOneTimePassword(email, {
      onSuccess: () => {
        handleClearErrors();
        setSnackbarTranslationKey('oneTimePassword.snackbarMessage.codeResent');
        setLastOtpTimestamp(new Date());
      },
      onError: handleError,
    });
  }, [
    email,
    handleClearErrors,
    handleError,
    onResendOneTimePassword,
    setValue,
  ]);

  const handleUsePassword = () => {
    if (mode === 'reAuthenticate') {
      navigate(`/${routes.reAuthenticatePassword}`, { state: { email } });
    } else if (mode === 'accountLinking') {
      navigate(`/${routes.accountLinkingPassword}`, { state: { email } });
    } else {
      navigate(`/${routes.login}/${routes.emailPasswordLogin}`, { state: { email } });
    }
  }

  const validationError = getErrorMessage(
    t,
    errors.oneTimePassword?.type === 'length'
      ? 'codeLength'
      : errors.oneTimePassword?.type,
  );

  useEffect(() => {
    // Get input ref on mount and focus it
    inputRef.current = document.getElementById('oneTimePassword');

    // onMount behaviour expected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitOnBlur = () => {
    handleSubmit(submitHandler)();
  };

  useEffect(() => {
    // unfocus input when form is valid to trigger auto submit
    if (isValid && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isValid]);

  const hasError = Boolean(validationError) || Boolean(networkErrorKey);
  const errorMessage =
    (networkErrorKey && t(networkErrorKey)) || validationError;

  showSnackbar({
    translationKey: snackbarTranslationKey,
    onClose: () => setSnackbarTranslationKey(undefined),
  });

  const handleEnterRecoveryCode = () => {
    navigate(`/${routes.unblockAccount}`, { state: { email } });
  };

  return (
    <>
      <BasicDialog
        open={isAccountBlocked}
        handlePrimaryAction={handleEnterRecoveryCode}
        title={t('oneTimePassword.accountBlockedDialog.title')}
        body={
          <Trans
            i18nKey="oneTimePassword.accountBlockedDialog.body"
            values={{ email }}
          />
        }
        primaryButtonText={t(
          'oneTimePassword.accountBlockedDialog.primaryButton',
        )}
      />
      <LoadingFullScreen
        isOpen={isRedirecting}
        title={t('oneTimePassword.successfulLoading')}
      />
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(submitHandler)();
        }}
        noValidate
        style={{ height: '100%' }}
      >
        <Stack
          justifyContent={{ xs: 'space-between', sm: 'unset' }}
          height="100%"
          space={{ lg: 2 }}
        >
          <Stack space={4} padding={3}>
            <Stack space={1}>
              <Box>
                <Typography variant={'h2'} color={'text.title'}>
                  {t('oneTimePassword.title')}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'body'}>
                  <Trans
                    i18nKey="oneTimePassword.subTitle"
                    values={{ email }}
                  />
                </Typography>
              </Box>
            </Stack>
            <Stack space={1}>
              <Controller
                control={control}
                name="oneTimePassword"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => {
                      field.onBlur();
                      handleSubmitOnBlur();
                    }}
                    nativeID="oneTimePassword"
                    value={field.value}
                    label={`${t('oneTimePassword.label')}`}
                    onChangeText={(value, unmaskedValue) => {
                      if (networkErrorKey) {
                        setNetworkErrorKey(undefined);
                      }

                      field.onChange(unmaskedValue || value);
                    }}
                    feedbackText={errorMessage}
                    isInvalid={hasError}
                    keyboardType="number-pad"
                    mask={OTP_FORMAT}
                    isRequired
                    autoComplete="one-time-code"
                  />
                )}
              />
              <OtpCountdownButton
                isDisabled={loading}
                onPress={handleResendCode}
                lastOtpTimestamp={lastOtpTimestamp}
                resendCodeIn={resendCodeIn}
              />
            </Stack>
          </Stack>
          <input type="submit" hidden />
          <Stack space={1} padding={3}>
            <Button isDisabled={loading} isLoading={loading} onPress={handleSubmit(submitHandler)}>
              {t('oneTimePassword.buttonText')}
            </Button>
            <Button 
              isDisabled={loading} 
              onPress={handleUsePassword} 
              testID='use-password-button'
              variant='tertiary'>
              {t('oneTimePassword.usePassword')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
