export const GoogleLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    className="injected-svg"
    data-src="/static/media/google-logo.2d3b48df.svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.64 9.70419C17.64 9.06601 17.5827 8.45237 17.4764 7.86328H9V11.3446H13.8436C13.635 12.4696 13.0009 13.4228 12.0477 14.061V16.3192H14.9564C16.6582 14.7524 17.64 12.4451 17.64 9.70419Z"
      fill="#4285F4"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99976 18.5009C11.4298 18.5009 13.467 17.695 14.9561 16.3205L12.0475 14.0623C11.2416 14.6023 10.2107 14.9214 8.99976 14.9214C6.65567 14.9214 4.67158 13.3382 3.96385 11.2109H0.957031V13.5428C2.43794 16.4841 5.48158 18.5009 8.99976 18.5009Z"
      fill="#34A853"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96409 11.2108C3.78409 10.6708 3.68182 10.094 3.68182 9.5008C3.68182 8.90762 3.78409 8.3308 3.96409 7.7908V5.45898H0.957273C0.347727 6.67398 0 8.04853 0 9.5008C0 10.9531 0.347727 12.3276 0.957273 13.5426L3.96409 11.2108Z"
      fill="#FBBC05"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99976 4.07955C10.3211 4.07955 11.5075 4.53364 12.4402 5.42545L15.0216 2.84409C13.4629 1.39182 11.4257 0.5 8.99976 0.5C5.48158 0.5 2.43794 2.51682 0.957031 5.45818L3.96385 7.79C4.67158 5.66273 6.65567 4.07955 8.99976 4.07955Z"
      fill="#EA4335"
    ></path>
  </svg>
);
