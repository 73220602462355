import { Typography } from '@taxfix/ds-components';
import { GoogleLogo } from '../images';
import './google-button.css';

type Props = {
  onClick: () => void;
  text: string | React.ReactElement;
};

export const GoogleButton = ({ onClick, text }: Props) => (
  <button type="button" className="google-btn" onClick={onClick}>
    <GoogleLogo />
    <Typography color="text.title" variant="button">
      {text}
    </Typography>
  </button>
);
