import { Auth0Error, Captcha } from 'auth0-js';

export enum AuthFlow {
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
  ACCOUNT_LINKING = 'account_linking',
  LOCK = 'LOCK',
}

export enum SocialConnection {
  google = 'google-oauth2',
  apple = 'apple',
}

export type LoginSubmitForm = {
  email: string;
  password: string;
};

export type PasswordlessSubmitForm = {
  email: string;
  oneTimePassword: string;
};

export type UnblockAccountSubmitForm = {
  email: string;
  oneTimeCode: string;
};

export type PasswordlessRequestOtpForm = {
  email: string;
};

export type LoginForm = LoginSubmitForm & { captcha?: Captcha };

export type SignupSubmitForm = {
  email: string;
  password: string;
  captcha?: Captcha;
};

export type SignupForm = SignupSubmitForm & { captcha?: string };

export type ExtraParams = {
  onSuccess?: () => void;
  onError?: (err: Auth0Error) => void;
};

export enum Auth0ErrorType {
  codeExpired = 'The verification code has expired. Please try to login again.',
  invalidCodeOrEmail = 'Wrong email or verification code.',
  tooManyAttempts = 'too_many_attempts',
  maxNumberOfAttempts = "You've reached the maximum number of attempts. Please try to login again.",
  passwordlessUnavailable = 'passwordless_unavailable',
  accountBlocked = 'user is blocked',
}

export enum UnblockAccountErrorType {
  codeExpired = 'code_expired',
  invalidCodeOrEmail = 'Unauthorized',
}
