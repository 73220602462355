import './app.css';
import { useMediaQuery, useTheme } from '@taxfix/ds-theme';
import { Header } from './header';
import { useAuth0 } from './hooks/auth0-hook';
import { useFeatureFlags } from './hooks/featureFlag-hook';
import { routes } from './router/routes';
import { AuthFlow } from './types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@taxfix/ds-components';

function App() {
  const { i18n } = useTranslation();
  const { config, authFlow, isAndroid } = useAuth0();
  const appLang = useRef(
    config.extraParams?.ui_locales || config.internalOptions?.ui_locales,
  );
  const theme = useTheme();
  const [isMobile] = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const navigate = useNavigate();
  const { isFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    // Set the same language as the application calling the universal login
    if (appLang.current && i18n.resolvedLanguage !== appLang.current) {
      i18n.changeLanguage(appLang.current);
      appLang.current = '';
    }
  }, [i18n]);

  useEffect(() => {
    switch (authFlow) {
      case AuthFlow.SIGNUP:
        navigate(routes.signup, { replace: true });
        break;
      case AuthFlow.ACCOUNT_LINKING:
        navigate(routes.accountLinkingOtp, { replace: true });
        break;
      case AuthFlow.LOCK:
        navigate(routes.reAuthenticateOtp, { replace: true });
        break;
      case AuthFlow.LOGIN:
      default:
        navigate(routes.login, { replace: true });
        break;
    }
  }, [isAndroid, authFlow, navigate, isFlagEnabled]);

  return (
    <>
      <Header isMobile={isMobile} />
      <Box zIndex={{ sm: -1 }} height="full">
        <Outlet />
      </Box>
    </>
  );
}

export default App;
