import { TFunction } from 'i18next';

const errorKey = {
  required: 'plausabilityError.required',
  email: 'plausabilityError.invalidEmail',
  length: 'plausabilityError.invalidPassword',
  codeLength: 'plausabilityError.invalidCode',
} as const;

export const getErrorMessage = (t: TFunction, errorType?: string) => {
  switch (errorType) {
    case 'required':
      return t(errorKey.required);
    case 'email':
      return t(errorKey.email);
    case 'min':
      return t(errorKey.length);
    // TODO: Once we migrate everything to OTP, we can remove this case
    case 'codeLength':
      return t(errorKey.codeLength);
    default:
      return;
  }
};
