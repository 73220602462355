import { useLocation } from 'react-router-dom';
import { Box } from '@taxfix/ds-components';
import { useTheme, useMediaQuery } from '@taxfix/ds-theme';
import { useAuth0 } from '../hooks/auth0-hook';
import { useAuthentication } from '../hooks/authentication';
import { UnblockAccount } from './components/unblock-account';

export const UnblockAccountContainer = () => {
  const { config } = useAuth0();
  const { state } = useLocation();
  const {
    handleUnblockAccount,
    isLoading,
    handleResendUnblockCode,
    handleResendOneTimePassword,
  } = useAuthentication();

  const theme = useTheme();
  const [isMobile] = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  const userEmail = state?.email || config?.extraParams?.login_hint;

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <UnblockAccount
          loading={isLoading}
          email={userEmail}
          onSubmit={handleUnblockAccount}
          onResendUnblockCode={handleResendUnblockCode}
          isMobile={isMobile}
          onResendOneTimePassword={handleResendOneTimePassword}
        />
      </Box>
    </Box>
  );
};
