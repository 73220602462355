import { Box } from '@taxfix/ds-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '../hooks/auth0-hook';
import { useAuthentication } from '../hooks/authentication';
import { SocialConnection } from '../types';
import { ReAuthenticate } from './components/re-authenticate';

export function ReAuthenticatePasswordContainer() {
  const { config } = useAuth0();
  const { handleEmailPasswordLogin, handleSocialLogin, isLoading } =
    useAuthentication();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (loading) {
      const { connection } = config;
      const socialConnection: SocialConnection | undefined = Object.values(SocialConnection).find(
        provider => provider === connection,
      );

      if (socialConnection) {
        handleSocialLogin(socialConnection);
        return;
      }

      const email = config?.extraParams?.login_hint;

      if (email) {
        setEmail(email);
      }

      setLoading(false);
    }
  }, [config, handleSocialLogin, loading, navigate]);

  if (loading) return null;

  return (
    <>
      <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Box
          width={{ xs: 'full', sm: 479 }}
          minHeight={400}
          height={{ xs: '100%', sm: 'auto' }}
        >
          <ReAuthenticate
            onSubmit={handleEmailPasswordLogin}
            email={email}
            loading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
}
