import auth0 from 'auth0-js';
import React from 'react';
import { auth0Config, isDev, isTest } from '../config';
import {
  detectPlatform,
  type Platform,
} from '../utils/platform-detection';
import { createWebAuthParams } from '../utils/createWebAuthParams';

if ((isDev || isTest) && auth0Config) {
  window.Auth0Context = {
    config: auth0Config,
    webAppUri: 'http://localhost:3000',
  };
}

type Auth0ContextType = {
  webAuth: auth0.WebAuth;
  config: Auth0Config;
  webAppUri: string;
  platform: Platform;
};

const Auth0Context = React.createContext({
  webAuth: new auth0.WebAuth({
    domain: '',
    clientID: '',
  }),
  config: {},
  webAppUri: '',
  platform: 'web',
} as Auth0ContextType);

export const useAuth0Context = (): Auth0ContextType => {
  return React.useContext(Auth0Context);
};

export type Auth0ProviderProps = {
  config: Auth0Config;
  baseURL: string;
  children: React.ReactNode;
  webAuth?: auth0.WebAuth;
  platform?: Platform;
};

const Auth0Provider = ({
  config,
  baseURL,
  webAuth,
  platform,
  children,
}: Auth0ProviderProps): React.ReactElement => {
  return (
    <Auth0Context.Provider
      value={{
        webAuth: webAuth ?? new auth0.WebAuth(createWebAuthParams(config)),
        config,
        webAppUri: baseURL,
        platform: platform ?? detectPlatform(config.callbackURL),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export { Auth0Provider };
