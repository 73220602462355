import {
  SnackbarAction,
  SnackbarClose,
  SnackbarContainer,
  SnackbarTitle,
  Stack,
  useSnackbar as useSnackbarDS,
} from '@taxfix/ds-components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Params = {
  onClose?: () => void;
  translationKey?: string;
  onActionPress?: () => void;
  actionTranslationKey?: string;
  keepOpen?: boolean;
};

export function useSnackbar() {
  const [snackbarId] = useState(Math.random().toString(36));
  const { t } = useTranslation();
  const { show, close, isActive } = useSnackbarDS();

  const showSnackbar = useCallback(
    ({
      translationKey,
      onClose,
      onActionPress,
      actionTranslationKey,
      keepOpen,
    }: Params) => {
      if (!isActive(snackbarId) && translationKey) {
        show({
          render: () => (
            <SnackbarContainer testID="snackbar-alert">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <SnackbarTitle>{t(translationKey)}</SnackbarTitle>
                <Stack direction="row" alignItems="center">
                  {onActionPress && actionTranslationKey && (
                    <SnackbarAction
                      onPress={() => {
                        close(snackbarId);
                        onActionPress();
                      }}
                    >
                      {t(actionTranslationKey)}
                    </SnackbarAction>
                  )}
                  <SnackbarClose
                    onPress={() => {
                      close(snackbarId);
                    }}
                  />
                </Stack>
              </Stack>
            </SnackbarContainer>
          ),
          id: snackbarId,
          onCloseComplete: onClose,
          duration: keepOpen ? null : undefined,
        });
      }
    },
    [close, isActive, show, snackbarId, t],
  );

  return {
    showSnackbar,
  };
}
