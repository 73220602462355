import { AppleButton } from '../../../components/apple-button';
import { GoogleButton } from '../../../components/google-button';
import { useAuth0 } from '../../../hooks/auth0-hook';
import { SocialConnection } from '../../../types';
import { useLoginContext } from '../../login-container';
import { Box, Button, Link, Stack, Typography } from '@taxfix/ds-components';
import EnvelopeIcon from '@taxfix/ds-icons/Envelope';
import { useTranslation } from 'react-i18next';

export const LoginProviderSelection = () => {
  const { t } = useTranslation();
  const { webAppUri } = useAuth0();
  const { onClickEmailLogin, onClickSocialLogin } = useLoginContext();

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <Stack
          height="100%"
          space={{ sm: 7.5 }}
          justifyContent="space-between"
          p={3}
        >
          <Stack space={3.5}>
            <Stack space={1}>
              <Box>
                <Typography variant={'h2'} color={'text.title'}>
                  {t('titleLogin')}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'body'}>{t('subTitle')}</Typography>
              </Box>
            </Stack>

            <Stack space={2}>
              <Box>
                <Button
                  testID="login-with-email-button"
                  onPress={onClickEmailLogin}
                  startIcon={<EnvelopeIcon />}
                >
                  {t('btnLoginWithEmailText')}
                </Button>
              </Box>
              <Box>
                <AppleButton
                  onClick={() => onClickSocialLogin(SocialConnection.apple)}
                  text={t('btnAppleText')}
                />
              </Box>
              <Box>
                <GoogleButton
                  onClick={() => onClickSocialLogin(SocialConnection.google)}
                  text={t('btnGoogleText')}
                />
              </Box>
            </Stack>
          </Stack>

          <Stack
            space={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            display={{ xs: 'none', sm: 'flex' }}
          >
            <Box>
              <Typography variant={'body'} color={'text.title'}>
                {t('noAccountYet')}
              </Typography>
            </Box>
            <Box>
              <Link href={webAppUri} variant="inline">
                {t('getStarted')}
              </Link>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
