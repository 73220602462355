import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SignupSubmitForm } from '../../types';
import yup from '../../utils/yup-extended';
import { useSignupContext } from '../signup-container';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@taxfix/ds-components';
import { PasswordField, validateStrongPassword, generateRandomPassword } from '../../components/password-field';
import { getErrorMessage } from '../../utils/validation-utils';
import { useEffect, useState } from 'react';
import { routes } from '../../router/routes';
import { useNavigateWithTransition } from '../../hooks/navigate-with-transition';
import { useFeatureFlags } from '../../hooks/featureFlag-hook';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(8),
  })
  .required();

export const SignupStepTwo = () => {
  const [passwordSubmited, setPasswordSubmited] = useState(false);
  const navigate = useNavigateWithTransition();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignupSubmitForm>({
    resolver: yupResolver(schema),
    values: {
      email: '',
      password: '',
    },
  });

  const { t } = useTranslation();
  const { errorMsg, onSubmit, loading, clearErrors, onPasswordlessStart, setSignupErrorMessage, paramSignupPasswordRequired } = useSignupContext();
  const { isFlagEnabled } = useFeatureFlags();
  const isSignupPasswordRequired = paramSignupPasswordRequired || isFlagEnabled('signup_password_required');

  if (!isSignupPasswordRequired && !control._formValues.password) {
    control._formValues.password = generateRandomPassword();
  }

  const submitHandler = (data: SignupSubmitForm) => {
    setPasswordSubmited(true);
    if (validateStrongPassword(data.password).isValid) {
      clearErrors();
      onSubmit(data, (err: any) => {
        const email = data.email;
        onPasswordlessStart(email, {
          onSuccess: () => navigate(`/${routes.login}/${routes.emailLogin}/${routes.otpScreen}`, { state: { email } }),
          onError: (err) => {
            setSignupErrorMessage();
          },
        });
      });
    }
  };

  const emailError = getErrorMessage(t, errors.email?.type);
  const passwordError = getErrorMessage(t, errors.password?.type);
  const networkError = errorMsg && t(errorMsg);

  // clear errors on unmount
  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, [clearErrors]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(submitHandler)();
      }}
      noValidate
      style={{ height: '100%' }}
    >
      <Box justifyContent="space-between" height="100%">
        <Stack space={4} py={3}>
          <Stack space={1} px={3}>
            <Typography variant={'h1'}>
              {t('signUpWithEmail.' + (isSignupPasswordRequired ? 'titlePassword' : 'title'))}
            </Typography>
            <Typography variant={'body'}>
              {t('signUpWithEmail.' + (isSignupPasswordRequired ? 'subTitlePassword' : 'subTitle'))}
            </Typography>
          </Stack>
          <Stack px={3}>
            <Box pt={1}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    testID="email-input"
                    label={`${t('emailLabel')}`}
                    onChangeText={e => {
                      field.onChange(e);
                      if (networkError) {
                        clearErrors();
                      }
                    }}
                    feedbackText={emailError || networkError}
                    isInvalid={Boolean(emailError || networkError)}
                    keyboardType="email-address"
                    isRequired
                  />
                )}
              />
            </Box>
            {isSignupPasswordRequired && (
              <Box pt={1}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <PasswordField
                      {...field}
                      testID="password-input"
                      value={field.value}
                      label={`${t('passwordLabel')}`}
                      onChangeText={field.onChange}
                      feedbackText={passwordError}
                      isInvalid={Boolean(passwordError)}
                      isRequired
                      validatePassword={true}
                      passwordSubmited={passwordSubmited}
                      setPasswordSubmited={setPasswordSubmited}
                    />
                  )}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <input type="submit" hidden />
        <Box p={3}>
          <Button
            testID="signup-with-email-password-button"
            isLoading={loading}
            onPress={handleSubmit(submitHandler)}
          >
            {t('signUpWithEmail.button')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
