import { useCallback } from 'react';

import { useFeatureFlagContext } from '../context/featureFlag-context';
import { FeatureFlagName } from '../constants';

export const useFeatureFlags = () => {
  const { flags } = useFeatureFlagContext();

  const getFlagValue = useCallback(
    (flag: FeatureFlagName) => {
      return flags.get(flag);
    },
    [flags],
  );

  const isFlagValue = useCallback(
    (flag: FeatureFlagName, expectedValue: string) => {
      return getFlagValue(flag) === expectedValue;
    },
    [getFlagValue],
  );

  const isFlagEnabled = useCallback(
    (flag: FeatureFlagName) => {
      return isFlagValue(flag, 'true');
    },
    [isFlagValue],
  );

  return { getFlagValue, isFlagEnabled, isFlagValue };
};
