import React from 'react';

export function parseFeatureFlags(
  params: Record<string, string | number | boolean | undefined | null>,
  config: FeatureFlagsConfig,
): FeatureFlags {
  const flags = new Map<string, string>();

  for (const [key, value] of Object.entries(params)) {
    // If not a feature flag or just the prefix, skip
    if (!key.startsWith(config.prefix) && key.length > config.prefix.length) {
      continue;
    }

    const flag = key.slice(config.prefix.length);

    const flagValue = value ?? config.defaultValue;

    flags.set(flag, flagValue.toString());
  }

  return flags;
}

type FeatureFlagContextType = {
  flags: FeatureFlags;
};

const FeatureFlagContext = React.createContext({
  flags: new Map<string, string>(),
} as FeatureFlagContextType);

export const useFeatureFlagContext = (): FeatureFlagContextType => {
  return React.useContext(FeatureFlagContext);
};

export type FeatureFlagProviderProps = {
  children: React.ReactNode;
  config: FeatureFlagsConfig;
  params: Record<string, string | number | boolean | undefined | null>;
};

const FeatureFlagProvider = ({
  config,
  params,
  children,
}: FeatureFlagProviderProps): React.ReactElement => {
  return (
    <FeatureFlagContext.Provider
      value={{
        flags: parseFeatureFlags(params, config),
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagProvider };
