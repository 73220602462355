export type Platform = 'android' | 'ios' | 'web';
export function detectPlatform(callbackURL: string): Platform {
  if (callbackURL.includes('/android/')) {
    return 'android';
  }

  if (callbackURL.includes('/ios/')) {
    return 'ios';
  }

  return 'web';
}
