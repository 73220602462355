import { Box } from '@taxfix/ds-components';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { accountLinkingCancelUrl } from '../constants';
import { useAuth0 } from '../hooks/auth0-hook';
import { useAuthentication } from '../hooks/authentication';
import { AuthFlow, LoginForm, SocialConnection } from '../types';
import { decodeJWT } from '../utils/jwt-utils';

async function cancelAccountLinking(url: string) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to cancel account linking with status ${response.status}`,
    );
  }

  const { redirectUrl } = await response.json();

  return redirectUrl;
}

export const AccountLinkingPasswordContainer = () => {
  const [error, setError] = useState<string>();
  const { config } = useAuth0();
  const { handleEmailPasswordLogin, handleSocialLogin, isLoading, authFlow } =
    useAuthentication();
  const isAccountLinking = Boolean(authFlow === AuthFlow.ACCOUNT_LINKING);
  const [loadingAccountLinking, setLoadingAccountLinking] =
    useState(isAccountLinking);
  const navigate = useNavigate();
  const [accountLinkingEmail, setAccountLinkingEmail] = useState<string>();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (isAccountLinking && loadingAccountLinking) {
      const { connection } = config;
      const socialConnection: SocialConnection | undefined = Object.values(SocialConnection).find(
        provider => provider === connection,
      );

      if (socialConnection) {
        handleSocialLogin(socialConnection);
        return;
      }

      const linkAccountToken = config?.extraParams?.link_account_token;

      const email = linkAccountToken && decodeJWT(linkAccountToken)?.email;
      setAccountLinkingEmail(email);
      setLoadingAccountLinking(false);
    }
  }, [
    config,
    handleSocialLogin,
    isAccountLinking,
    loadingAccountLinking,
    navigate,
  ]);

  const handleCancelAccountLinking = async () => {
    // If the client_id is not present in the query params,
    // add it since this is required by the cancel account linking endpoint.
    if (!searchParams.has('client_id')) {
      searchParams.set('client_id', config.clientID);
    }

    const redirectUrl = await cancelAccountLinking(
      `${accountLinkingCancelUrl}?${searchParams.toString()}`,
    );

    window.location.replace(redirectUrl);
  };

  const handleSecondaryBtnClick = () => {
    setError(undefined);
    return handleCancelAccountLinking();
  };

  if (loadingAccountLinking) return null;

  return (
    <>
      <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Box
          width={{ xs: 'full', sm: 479 }}
          minHeight={400}
          height={{ xs: '100%', sm: 'auto' }}
        >
          <Outlet
            context={{
              onSubmit: handleEmailPasswordLogin,
              errorMsg: error,
              onSecondaryBtnClick: handleSecondaryBtnClick,
              accountLinkingEmail: accountLinkingEmail,
              loading: isLoading,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

type AccountLinkingHandlers = {
  onSubmit: (data: LoginForm) => void;
  errorMsg?: string;
  onSecondaryBtnClick: () => void;
  accountLinkingEmail?: string;
  loading: boolean;
};

export function useAccountLinkingHandlers() {
  return useOutletContext<AccountLinkingHandlers>();
}
