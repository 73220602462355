import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@taxfix/ds-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AuthenticationHook } from '../../hooks/authentication';
import yup from '../../utils/yup-extended';
import { useSnackbar } from '../../hooks/snack-bar';
import { getErrorMessage } from '../../utils/validation-utils';
import { UnblockAccountErrorType, UnblockAccountSubmitForm } from '../../types';
import { useNavigateWithTransition } from '../../hooks/navigate-with-transition';
import { OtpCountdownButton } from '../../components/otp-countdown-button';
import { OTP_EXPIRATION_SECONDS } from '../../constants';

const schema = yup
  .object({
    email: yup.string().email().required(),
    oneTimeCode: yup.string().required().length(6),
  })
  .required();

const OTP_FORMAT = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

type Props = {
  email: string;
  onSubmit: AuthenticationHook['handleUnblockAccount'];
  loading?: AuthenticationHook['isLoading'];
  onResendUnblockCode: AuthenticationHook['handleResendUnblockCode'];
  onResendOneTimePassword: AuthenticationHook['handleResendOneTimePassword'];
  isMobile?: boolean;
  resendCodeIn?: number;
};

export const UnblockAccount = ({
  loading,
  email,
  onSubmit,
  onResendUnblockCode,
  onResendOneTimePassword,
  resendCodeIn = OTP_EXPIRATION_SECONDS,
}: Props) => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<UnblockAccountSubmitForm>({
    resolver: yupResolver(schema),
    values: {
      oneTimeCode: '',
      email,
    },
  });
  const inputRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const [networkErrorKey, setNetworkErrorKey] = useState<string>();
  const { showSnackbar } = useSnackbar();
  const [snackbarTranslationKey, setSnackbarTranslationKey] =
    useState<string>();
  const [lastOtpTimestamp, setLastOtpTimestamp] = useState(new Date());
  const navigate = useNavigateWithTransition();

  const handleError = useCallback((error: Error) => {
    switch (error.message) {
      case UnblockAccountErrorType.codeExpired:
        // If the code is expired we need to allow the user to request a new one
        setNetworkErrorKey('unblockAccount.error.codeExpired');
        break;
      case UnblockAccountErrorType.invalidCodeOrEmail:
        setNetworkErrorKey('unblockAccount.error.codeInvalid');
        break;
      default:
        setNetworkErrorKey('unblockAccount.error.generalError');
        break;
    }
  }, []);

  const handleClearErrors = useCallback(() => {
    clearErrors();
    setNetworkErrorKey(undefined);
  }, [clearErrors]);

  const submitHandler = async () => {
    if (networkErrorKey) {
      handleClearErrors();
    }

    if (loading) return;

    try {
      await onSubmit(email, getValues('oneTimeCode'));

      onResendOneTimePassword(email, {
        onSuccess: () => {
          navigate(-1);
        },
        /*
          Navigate back even if the resend fails
          to move the user away from the unblock account screen
          after the account is unblocked
        */
        onError: () => {
          navigate(-1);
        },
      });
    } catch (error) {
      handleError(error as Error);
    }
  };

  const handleResendCode = useCallback(async () => {
    handleClearErrors();
    setValue('oneTimeCode', '');
    setLastOtpTimestamp(new Date());
    try {
      await onResendUnblockCode(email);
      setSnackbarTranslationKey('unblockAccount.snackbar.codeResent');
      setLastOtpTimestamp(new Date());
    } catch (error) {
      handleError(error as Error);
    }
  }, [email, handleClearErrors, handleError, onResendUnblockCode, setValue]);

  const validationError = getErrorMessage(
    t,
    errors.oneTimeCode?.type === 'length'
      ? 'codeLength'
      : errors.oneTimeCode?.type,
  );

  useEffect(() => {
    // Get input ref on mount and focus it
    inputRef.current = document.getElementById('oneTimeCode');

    if (email && resendCodeIn > 0) {
      onResendUnblockCode(email).catch((error) => {
        if (error.statusCode !== 425) {
          handleError(error as Error)
        }
      });
    }

    // onMount behaviour expected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitOnBlur = () => {
    handleSubmit(submitHandler)();
  };

  useEffect(() => {
    // unfocus input when form is valid to trigger auto submit
    if (isValid  && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isValid]);

  const hasError = Boolean(validationError) || Boolean(networkErrorKey);
  const errorMessage =
    (networkErrorKey && t(networkErrorKey)) || validationError;

  showSnackbar({
    translationKey: snackbarTranslationKey,
    onClose: () => setSnackbarTranslationKey(undefined),
  });

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(submitHandler)();
        }}
        noValidate
        style={{ height: '100%' }}
      >
        <Stack
          justifyContent={{ xs: 'space-between', sm: 'unset' }}
          height="100%"
          space={{ lg: 2 }}
        >
          <Stack space={4} padding={3}>
            <Stack space={1}>
              <Box>
                <Typography variant={'h2'}>
                  {t('unblockAccount.title')}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'body'}>
                  <Trans i18nKey="unblockAccount.subTitle" values={{ email }} />
                </Typography>
              </Box>
            </Stack>
            <Stack space={1}>
              <Stack direction={'row'} space={2}>
                <Box flex={2}>
                  <Controller
                    control={control}
                    name="oneTimeCode"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onBlur={() => {
                          field.onBlur();
                          handleSubmitOnBlur();
                        }}
                        nativeID="oneTimeCode"
                        value={field.value}
                        label={`${t('unblockAccount.label')}`}
                        onChangeText={(value, umaskedValue) => {
                          if (networkErrorKey) {
                            setNetworkErrorKey(undefined);
                          }

                          field.onChange(umaskedValue || value);
                        }}
                        feedbackText={errorMessage}
                        isInvalid={hasError}
                        keyboardType="number-pad"
                        mask={OTP_FORMAT}
                        isRequired
                        autoComplete="one-time-code"
                      />
                    )}
                  />
                </Box>
              </Stack>
              <OtpCountdownButton
                isDisabled={loading}
                onPress={handleResendCode}
                lastOtpTimestamp={lastOtpTimestamp}
                resendCodeIn={resendCodeIn}
              />
            </Stack>
          </Stack>
          <input type="submit" hidden />
          <Stack space={1} padding={3}>
            <Button isLoading={loading} onPress={handleSubmit(submitHandler)}>
              {t('unblockAccount.buttonText')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
