import { useEffect, useState } from 'react';
import { useAuth0 } from '../hooks/auth0-hook';
import { useAuthentication } from '../hooks/authentication';
import { SocialConnection } from '../types';
import { OtpInputContainer } from '../shared/one-time-password/one-time-password-container';

export function ReAuthenticateOtpContainer() {
  const { config } = useAuth0();
  const { handleSocialLogin, isLoading, handlePasswordlessStart } =
    useAuthentication();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      const { connection } = config;
      const socialConnection: SocialConnection | undefined = Object.values(SocialConnection).find(
        provider => provider === connection,
      );

      if (socialConnection) {
        handleSocialLogin(socialConnection);
        return;
      }

      const email = config?.extraParams?.login_hint;

      if (email) {
        handlePasswordlessStart(email, {
          onSuccess: () => setLoading(false),
          onError: () => {
            setLoading(false);
          },
        });
      }
    }
  }, [config, handlePasswordlessStart, handleSocialLogin, loading]);

  if (loading || isLoading) return null;

  return <OtpInputContainer mode='reAuthenticate'/>;
}
