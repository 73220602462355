import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@taxfix/ds-theme';
import { useAuthentication } from '../../hooks/authentication';
import { OtpInputScreen, otpMode } from './components/one-time-password-screen';
import { useAuth0 } from '../../hooks/auth0-hook';
import { Box } from '@taxfix/ds-components';
import { useEffect, useState } from 'react';

export const OtpInputContainer = ({ email,mode }: { email?: string,mode: otpMode }) => {
  const { config } = useAuth0();
  const { state } = useLocation();
  const { handlePasswordlessLogin, isLoading, handleResendOneTimePassword } =
    useAuthentication();
  const theme = useTheme();
  const [isMobile] = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const navigate = useNavigate();
  const [isBlocked] = useState(state?.isBlocked);
  const userEmail = email || state?.email || config?.extraParams?.login_hint;

  useEffect(() => {
    if (!isBlocked) return;

    /*
    This behavior is needed in order to navigate back
    from the unblock screen after the account is unblocked
    and not show the account blocked dialog.
    */
    navigate('.', { replace: true, state: { email: userEmail } }); // <-- redirect to current path w/o isBlocked state
  }, [isBlocked, navigate, userEmail]);

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 479 }}
        minHeight={400}
        height={{ xs: '100%', sm: 'auto' }}
      >
        <OtpInputScreen
          loading={isLoading}
          email={userEmail}
          onPasswordlessLogin={handlePasswordlessLogin}
          onResendOneTimePassword={handleResendOneTimePassword}
          isMobile={isMobile}
          isBlocked={isBlocked}
          mode={mode}
        />
      </Box>
    </Box>
  );
};
