import { useTranslation } from 'react-i18next';
import { useAuth0 } from './hooks/auth0-hook';
import {
  AppBar,
  AppBarContent,
  AppBarLogo,
  MenuItem,
  NavItem,
  Pressable,
  Stack,
  TaxfixLogo,
} from '@taxfix/ds-components';
import { useTheme } from '@taxfix/ds-theme';
import Globe from '@taxfix/ds-icons/Globe';

import './header.css';
import { useState } from 'react';

const supportedLanguage = {
  en: 'en',
  de: 'de',
};

export const Header = ({ isMobile }: { isMobile?: boolean }) => {
  const { i18n, t } = useTranslation();
  const { webAppUri } = useAuth0();
  const theme = useTheme();

  const [languageOptions] = useState([
    {
      locale: supportedLanguage.en,
      label: t('header.lang-switch.label-en'),
    },
    {
      locale: supportedLanguage.de,
      label: t('header.lang-switch.label-de'),
    },
  ]);

  const getSelectedLanguageLabel = () => {
    switch (i18n.resolvedLanguage) {
      case supportedLanguage.en:
        return t('header.lang-switch.label-en');
      case supportedLanguage.de:
      default:
        return t('header.lang-switch.label-de');
    }
  };
  const handleLanguageChange = (lang: string) => {
    if (!document.startViewTransition) {
      return i18n.changeLanguage(lang);
    } else {
      document.startViewTransition(() => {
        i18n.changeLanguage(lang);
      });
    }
  };

  if (isMobile) return null;

  return (
    <AppBar style={{ position: 'fixed', width: '100%' }}>
      <AppBarContent
        testID="nav-bar-legacy"
        // @ts-ignore
        style={{
          backgroundColor: theme.colors.grey.surface3,
        }}
      >
        <Stack direction="row" space={2}>
          <Pressable
            onPress={() => {
              document.location.replace(webAppUri);
            }}
          >
            <AppBarLogo testID="appBarLogo">
              <TaxfixLogo variant="global" height="full" />
            </AppBarLogo>
          </Pressable>
        </Stack>
        <Stack direction="row" space={2}>
          <NavItem
            icon={<Globe />}
            testID="languageSwitchNavItem"
            label={getSelectedLanguageLabel()}
            // @ts-ignore
            style={{ backgroundColor: theme.colors.grey.surface3 }}
          >
            {languageOptions.map(({ locale, label }, i: number) => {
              const active = i18n.resolvedLanguage === locale;

              return (
                <MenuItem
                  key={locale}
                  isSelected={active}
                  onPress={() => handleLanguageChange(locale)}
                  testID={`btn-lang-${locale}`}
                >
                  {label}
                </MenuItem>
              );
            })}
          </NavItem>
        </Stack>
      </AppBarContent>
    </AppBar>
  );
};
