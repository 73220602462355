export const routes = {
  home: '/',
  login: 'login',
  signup: 'signup',
  accountLinkingOtp: 'account-linking-otp',
  accountLinkingPassword: 'account-linking-password',
  emailLogin: 'email-login',
  otpScreen: 'otp',
  emailSignup: 'email-signup',
  reAuthenticateOtp: 're-authenticate-otp',
  reAuthenticatePassword: 're-authenticate-password',
  unblockAccount: 'unblock-account',
  emailPasswordLogin: 'email-password-login',
  resetPassword: 'reset-password',
};
