// Reference: https://github.com/jquense/yup/issues/312#issuecomment-745034006
import * as yup from 'yup';

// Overwrite email validation
yup.addMethod(yup.string, 'email', function () {
  /**
   * Following Email validation regex pattern is being used on below mentioned projects
   * 1. https://github.com/taxfix/users/commit/0dcba6431dc9bf2694914c6e821369fa0e64b1fb
   */
  const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([\w-]+\.)+[\w-]{2,}$/g;
  return this.matches(pattern, {
    name: 'email',
    message: 'The field should have email only',
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    email(): this;
  }
}

export default yup;
