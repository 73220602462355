import { PasswordField } from '../../components/password-field';
import { LoginForm, LoginSubmitForm } from '../../types';
import { getErrorMessage } from '../../utils/validation-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography, Link } from '@taxfix/ds-components';
import User from '@taxfix/ds-icons/User';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { auth0Config } from '../../config';
import { routes } from '../../router/routes';
import { useNavigateWithTransition } from '../../hooks/navigate-with-transition';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(4),
  })
  .required();

type Props = {
  onSubmit: (data: LoginForm) => void;
  email: string;
  loading?: boolean;
};

export const ReAuthenticate = ({ email, onSubmit, loading }: Props) => {
  const navigate = useNavigateWithTransition();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<LoginSubmitForm>({
    resolver: yupResolver(schema),
    values: {
      email: email,
      password: '',
    },
  });
  const { t } = useTranslation();

  const handleForgotPassword = () => {
    navigate(`/${routes.resetPassword}`, { state: { userEmail: email } });
  }

  const handleBacktoOtp = () => {
    navigate(`/${routes.reAuthenticateOtp}`, { state: { userEmail: email } });
  }

  const submitHandler = () => {
    onSubmit({
      email: getValues('email'),
      password: getValues('password'),
    });
  };

  const passwordError = getErrorMessage(t, errors.password?.type);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(submitHandler)();
      }}
      noValidate
      style={{ height: '100%' }}
    >
      <Stack
        justifyContent={{ xs: 'space-between', sm: 'unset' }}
        height="100%"
        space={{ lg: 2 }}
      >
        <Stack padding={3}>
          <Stack space={1}>
            <Box>
              <Typography variant={'h2'} color={'text.title'}>
                {t('reAuthenticate.title')}
              </Typography>
            </Box>
          </Stack>

          <Stack space={1}>
            <Stack space={1} direction="row" paddingY={3}>
              <User color="text.title" />
              <Typography variant={'body'}>{email}</Typography>
            </Stack>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <PasswordField
                  {...field}
                  value={field.value}
                  label={`${t('reAuthenticate.passwordLabel')}`}
                  onChangeText={field.onChange}
                  feedbackText={passwordError}
                  isInvalid={Boolean(passwordError)}
                />
              )}
            />
            <Link onPress={handleForgotPassword}>
              {t('reAuthenticate.forgotPassword')}
            </Link>
            <Link href={auth0Config.supportURL} isExternal>
              {t('reAuthenticate.haveTrouble')}
            </Link>
          </Stack>
        </Stack>
        <input type="submit" hidden />
        <Box padding={3}>
          <Button
            isLoading={loading}
            isDisabled={loading}
            onPress={handleSubmit(submitHandler)}
          >
            {t('reAuthenticate.buttonText')}
          </Button>
          <Button
            isDisabled={loading}
            onPress={handleBacktoOtp}
            variant="tertiary"
          >
            {t('reAuthenticate.backToOtp')}
          </Button>
        </Box>
      </Stack>
    </form >
  );
};
