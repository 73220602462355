import { getErrorMessage } from '../../utils/validation-utils';
import yup from '../../utils/yup-extended';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, TextField, Typography } from '@taxfix/ds-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ResetPasswordProps = {
  onSubmit: () => void;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  userEmail: string;
};
type ResetPassword = {
  email: string;
};

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const ResetPasswordStepOne = ({
  onSubmit,
  setEmail,
  isLoading,
  userEmail,
}: ResetPasswordProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPassword>({
    resolver: yupResolver(schema),
    values: {
      email: userEmail,
    },

  });

  const emailError = getErrorMessage(t, errors.email?.type);

  return (
    // wrapping in form to enable submitting the form on press Enter
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      style={{ height: '100%' }}
    >
      <Stack
        justifyContent={{ xs: 'space-between', sm: 'unset' }}
        height="100%"
        space={{ lg: 2 }}
      >
        <Stack width="full" space={5} padding={3}>
          <Stack width="full" space={1}>
            <Typography variant="h2">{t('resetPassword.stepOneTitle')}</Typography>
            <Typography variant="body">
              {t('resetPassword.stepOneSubTitle')}
            </Typography>
          </Stack>

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextField
                {...register('email')}
                onChangeText={val => {
                  setEmail(val);
                  onChange(val);
                }}
                value={value}
                isInvalid={Boolean(emailError)}
                feedbackText={emailError}
                label={t('resetPassword.inputStepOneLabel') as string}
                keyboardType="email-address"
              />
            )}
          />
        </Stack>
        <input type="submit" hidden />
        <Stack padding={3}>
          <Button
            isDisabled={isLoading}
            accessibilityRole="button"
            isLoading={isLoading}
            onPress={handleSubmit(onSubmit)}
            width="full"
          >
            {t('resetPassword.stepOneButton')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
