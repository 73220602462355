import { useCallback, useState } from 'react';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';
import { SignupSubmitForm } from '../types';
import { Box } from '@taxfix/ds-components';
import { routes } from '../router/routes';
import { AuthenticationHook, useAuthentication } from '../hooks/authentication';
import { useHandleSocialAuthError } from '../hooks/handle-social-auth-error';
import { useNavigateWithTransition } from '../hooks/navigate-with-transition';

export const SignupContainer = () => {
  useHandleSocialAuthError();
  const navigate = useNavigateWithTransition();
  const {
    handleSocialSignup,
    isLoading,
    handleEmailPasswordSignup,
    handlePasswordlessStart,
    setSignupErrorMessage,
    clearErrors,
    error,
  } = useAuthentication();
  const [searchParams] = useSearchParams();
  const [paramSignupPasswordRequired, setParamSignupPasswordRequired] = useState(false);
  if (!paramSignupPasswordRequired && Boolean(searchParams.get("ff_signup_password_required"))) {
    setParamSignupPasswordRequired(true);
  }

  const handleEmailSignup = useCallback(() => {
    navigate(routes.emailSignup);
  }, [navigate]);

  return (
    <Box height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={{ xs: 'full', sm: 460 }}
        minHeight={{ xs: '100%', sm: 392 }}
        justifyContent={{
          xs: 'space-between',
        }}
      >
        <Outlet
          context={{
            onClickSocialSignup: handleSocialSignup,
            onClickEmailSignup: handleEmailSignup,
            onSubmit: handleEmailPasswordSignup,
            onPasswordlessStart: handlePasswordlessStart,
            setSignupErrorMessage,
            errorMsg: error,
            loading: isLoading,
            clearErrors,
            paramSignupPasswordRequired
          }}
        />
      </Box>
    </Box>
  );
};

type SignupHandlers = {
  onClickSocialSignup: (conn: string) => void;
  onClickEmailSignup: () => void;
  onSubmit: (data: SignupSubmitForm, onError: (err: any) => void) => void;
  onPasswordlessStart: AuthenticationHook['handlePasswordlessStart'];
  setSignupErrorMessage: () => void;
  loading: boolean;
  errorMsg?: string;
  clearErrors: () => void;
  paramSignupPasswordRequired: boolean;
};

export function useSignupContext() {
  return useOutletContext<SignupHandlers>();
}
